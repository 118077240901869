body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, .alert, a, .td-name, td, button.close {
    font-family: 'Proxima N W01','db_heavent', 'Open Sans' , sans-serif !important;
}
::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: rgba(85, 85, 85, 0.26);
}
.page-content{
    background-image: url("../../assets/img/bgimg.jpg") !important;
    background-size: cover !important;
    min-height: 50%;
}
.page-tracking{
    background-image: url("../../assets/img/bg-circle.svg") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top 100px left 0px;
    min-height: 65%;
}
@media screen and (max-width: 768px){
    .page-tracking{
        background-image: url("../../assets/img/bg-circle.svg") !important;
        background-repeat: no-repeat;
        background-size: 120%;
        background-position: top 78px left 38px;
        min-height: 50%;
    }
}
.nav-item .active{
    border-bottom: 1px solid #D90101 !important;
    color:#D90101 !important;
}
.tab-content  {
    border: 1px solid #ddd;
    padding: 30px 70px 180px 70px;
}
.main-content {
    position: absolute;
    width: 100%;
}
.content {
    position: relative;
    padding: 0px 0px 50px 0px;
}
.content-table{
    position: relative;
    top: 40%;
    left: 0%;
    transform: translate(0%, -50%);
}
.main-content .tab-content{
    background-color: white;
    border-radius: 4px;
    box-shadow: 1px 3px 1px #eaeaea;
}
.text-white{
    color: white;
}
.btn:hover, .btn:focus, .btn:active, .btn.active, .open > .btn.dropdown-toggle {
    background-color: transparent;
    color: #777777;
    border-color: #777777;
}
.btn-white{
    color: #000000;
    background-color: white;
    border-color: #E3E3E3;
}
.btn-white:hover {
    color: #000000;
    background-color: white;
    border-color: #E3E3E3;
}
.btn:hover, .btn:focus {
    opacity: 1;
    filter: alpha(opacity=100);
    outline: 0 !important;
    box-shadow:none;
}
.navbar {
    width: 100%;
    padding: 15px 0px;
}
.navbar .btn-tracking{
    color: black;
    text-decoration: unset;
}
.navbar .btn-payment{
    color: black;
    text-decoration: unset;
    margin-left: 10px;
}
.btn-getprice{
    border-radius: 30px;
    margin-top: 20px;

}
.btn-danger {
    color: #fff !important;
    background-color: #D90101 !important;
    border-color: #D90101 !important;
}
.btn-danger:hover {
    color: #fff !important;
    background-color: #9D0000 !important;
    border-color: #9D0000 !important;
}
.btn-outline-danger{
    color: #D90101;
    border-color: #D90101;
}
.btn-outline-danger:hover {
    color: #fff;
    border-color: #dc3545;
    background-color: #dc3545 !important;
}
.btn-outline-danger:focus{
    color: #fff;
    border-color: #D90101;
    background-color: #9D0000 !important;
}
.content table thead th{
    border: none;
}
table tbody td , table thead th{
    height: 72px;
}
.tracking_status tbody td , .tracking_status thead th{
    height: 50px;
    padding-left: 30px;
}
.tracking_status{
    -webkit-box-shadow: 0 0px 3px 0px rgba(151, 151, 151, 0.5);
    box-shadow: 0 0px 3px 0px rgba(151, 151, 151, 0.5);
}
.tracking_status thead th{
    background-color: #d0d0d0;
}
table tbody td , table thead th{
    vertical-align: middle !important;
}
.table {
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
    margin-top: 50px;
}
.hide {
    display: none;
}
.btn.disabled, .btn:disabled {
    opacity: .65;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #EC545F !important;
    border-color: #EC545F !important;
}
@media screen and (max-width: 768px){
    .content h1{ 
        font-size: 25px;
    }
    .content {
        position: relative;
        padding: 50px 0px 50px 0px;
    }
   
}
  
@media screen and (max-width: 414px){
    .content h1{ 
        font-size: 25px !important;
    }
    .content {
        position: relative;
        padding: 50px 0px 50px 0px;
    }
}
  
@media screen and (max-width: 375px){
    .content h1{ 
        font-size: 25px !important;
    }
    .content {
        position: relative;
        padding: 50px 0px 50px 0px;
    }
}
  
@media screen and (max-width: 320px){
    .content h1{ 
        font-size: 25px !important;
    }
    .content {
        position: relative;
        padding: 50px 0px 50px 0px;
    }
}